import {MeetingDetailsOutput} from "../../../api/sdl";
import {Box, Button, Stack, Typography} from "@mui/material";
import React from "react";
import {MeetingDetails} from "./MeetingDetails";
import {useCancelMeetingApi} from "../api/useCancelMeetingApi";
import {useIsMobile} from "../../../shared";

interface Props {
  item: MeetingDetailsOutput,
  onCancelMeeting: () => void,
  onRescheduleClick: () => void,
}

const FutureMeetingRowItem = ({item, onCancelMeeting, onRescheduleClick}: Props) => {
  const onCancelClick = useCancelMeetingApi(onCancelMeeting);
  const isMobile = useIsMobile()

  return <Box data-test='meeting-block' sx={{display: 'flex', alignItems: 'center', gap: 3, background: '#E1F5FB', borderRadius: '10px', p: '16px 18px', mb: '20px'}}>
    {!isMobile &&
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3, background: '#37C9EF', borderRadius: '24px', width: {xs:'58px', md:'48px'}, height: 48}}>
        <Box component={'img'} src={`/img/teenyicons/appointments-blue.svg`} width={22} height={22} />
      </Box>
    }
    <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 1, width: 1}}>

      <MeetingDetails item={item} />

      <Stack spacing={{xs: 1, md: 2}} direction={{xs: "column", md: "row"}}>
        <Button data-test='join' sx={{ml: 'auto'}} onClick={() => window.open(item.zoomLink)}>Join meeting</Button>
        <Stack direction={{xs: "row"}} sx={{justifyContent: "space-between"}}>
          <Button data-test='reschedule' onClick={onRescheduleClick}>Reschedule</Button>
          <Button data-test='cancel' onClick={() => onCancelClick(item.meetingId)} variant={'text'} sx={{fontWeight: 600, color: '#6D6D6D'}}>Cancel meeting</Button>
        </Stack>
      </Stack>
    </Box>
  </Box>
}

export default FutureMeetingRowItem;
